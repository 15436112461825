<template>

<div class="o365-header" :class="{'o365-multiline-header': dataGridControl.multilineHeader}" ref="headerRowRef">

    <template v-if="hasBulkUpdate">
        <o-dropdown v-if="bulkUpdateIsOpen" ref="bulkUpdateDropdown" :targetRef="bulkUpdateCurrentTarget" v-slot:dropdown="scope" placement="bottom-end"
            @onbeforeclose="bulkUpdateIsOpen = false;">
            <div :ref="scope.container">
                <o-bulk-update :data-grid-control="dataGridControl" :data-object="dataGridControl.dataObject" :column="bulkUpdateCurrentCol" @oncancel="scope.close()"></o-bulk-update>
            </div>
        </o-dropdown>
    </template>

    <div class="w-100" style="display: flex; flex-direction: row; position: relative;">

        <div class=" w-100" style="display: flex; flex-direction: column;" ref="newRecordsContainerRef">

            <slot name="top"></slot>

            <div v-if="!dataGridControl.noHeaderRow && dataGridControl.dataColumns.hasGroupedColumns" class="o365-header-group-row-container vertical-scrollbar-gutter-2" style="display: flex; flex-direction: row;">


                <div class="o365-header-pinned-left" v-if="dataGridControl.dataColumns.hasPinnedLeftColumns"
                :style="[{'min-width':dataGridControl.dataColumns.leftPinnedWidth + 'px'},{'max-width':dataGridControl.dataColumns.leftPinnedWidth + 'px'},{'width':dataGridControl.dataColumns.leftPinnedWidth + 'px'}]">

                    <template v-if="dataGridControl.dataColumns.hasGroupedColumns">
                        <div class="o365-header-row o365-header-group-row" v-for="(groupRow, groupRowIndex) in columnGroups" :key="groupRowIndex" :o365-header-group-row="groupRowIndex">
                            <template v-for="(group, groupIndex) in groupRow" :key="groupIndex">
                                <template v-if="group.pinned==='left'">
                                    <header-group-cell :group="group" :groupIndex="groupIndex" :groupRowIndex="groupRowIndex"></header-group-cell>
                                </template>
                            </template>
                        </div>
                    </template>

                </div>

                <div class="o365-header-viewport">
                    <div class="o365-grid-container" :class="{'h-100': dataGridControl.multilineHeader}" :style="[{'width':dataGridControl.dataColumns.centerWidth + 'px'}]">

                        <template v-if="dataGridControl.dataColumns.hasGroupedColumns">
                            <div class="o365-header-row o365-header-group-row" v-for="(groupRow, groupRowIndex) in columnGroups" :key="groupRowIndex" :o365-header-group-row="groupRowIndex">
                                <template v-for="(group, groupIndex) in groupRow" :key="groupIndex">
                                    <template v-if="!group.pinned">
                                        <header-group-cell :group="group" :groupIndex="groupIndex" :groupRowIndex="groupRowIndex"></header-group-cell>
                                    </template>
                                </template>
                            </div>
                        </template>

                    </div>

                </div>

                <div class="o365-header-pinned-right o365-header-pinned-right-headers" v-if="dataGridControl.dataColumns.hasPinnedRightColumns"
                    :style="[{'min-width':dataGridControl.dataColumns.rightPinnedWidth + 'px'},{'max-width':dataGridControl.dataColumns.rightPinnedWidth + 'px'},{'width':dataGridControl.dataColumns.rightPinnedWidth + 'px'}]">

                    <template v-if="dataGridControl.dataColumns.hasGroupedColumns">
                        <div class="o365-header-row o365-header-group-row" v-for="(groupRow, groupRowIndex) in columnGroups" :key="groupRowIndex" :o365-header-group-row="groupRowIndex">
                            <template v-for="(group, groupIndex) in groupRow" :key="groupIndex">
                                <template v-if="group.pinned==='right'">
                                    <header-group-cell :group="group" :groupIndex="groupIndex" :groupRowIndex="groupRowIndex" ></header-group-cell>
                                </template>
                            </template>
                        </div>
                    </template>

                </div>

            </div>

            <div class="o365-header-row-container vertical-scrollbar-gutter-2" style="display: flex; flex-direction: row;" data-o365-container="H" v-if="!dataGridControl.noHeaderRow">


                <div class="o365-header-pinned-left" v-if="dataGridControl.dataColumns.hasPinnedLeftColumns"
                :style="[{'min-width':dataGridControl.dataColumns.leftPinnedWidth + 'px'},{'max-width':dataGridControl.dataColumns.leftPinnedWidth + 'px'},{'width':dataGridControl.dataColumns.leftPinnedWidth + 'px'}]">

                    <div class="o365-header-row" data-o365-rowindex="0">
                        <template v-for="(col, colIndex) in columns" :key="colIndex">
                            <template v-if="!col.hide && col.pinned === 'left'">
                                <header-cell :col="col" :colIndex="colIndex" :dataGridControl="dataGridControl" :closeNewRecordsPanel="closeNewRecordsPanel" :bulkUpdateOpen="bulkUpdateOpen" :bulkUpdateSelectedRows="selectedRows"
                                    :showNewRecordButton="showNewRecordButton" :hasNewRecords="hasNewRecords" :createNewRecord="createNewRecord" :group-by="groupBy" :use-left-column-sizers="useLeftColumnSizers"></header-cell>
                            </template>
                        </template>
                    </div>

                </div>

                <div class="o365-header-viewport">
                    <div class="o365-grid-container" :class="{'h-100': dataGridControl.multilineHeader}" :style="[{'width':dataGridControl.dataColumns.centerWidth + 'px'}]">

                        <div class="o365-header-row" data-o365-rowindex="0">
                            <template v-for="(col, colIndex) in columns" :key="colIndex">
                                <template v-if="!col.hide && !col.pinned">
                                    <header-cell :col="col" :colIndex="colIndex" :dataGridControl="dataGridControl" :closeNewRecordsPanel="closeNewRecordsPanel" :bulkUpdateOpen="bulkUpdateOpen" :bulkUpdateSelectedRows="selectedRows"
                                        :showNewRecordButton="showNewRecordButton" :hasNewRecords="hasNewRecords" :createNewRecord="createNewRecord" :group-by="groupBy" :use-left-column-sizers="useLeftColumnSizers"></header-cell>
                                </template>
                            </template>
                        </div>

                    </div>

                </div>

                <div class="o365-header-pinned-right o365-header-pinned-right-headers" v-if="dataGridControl.dataColumns.hasPinnedRightColumns"
                    :style="[{'min-width':dataGridControl.dataColumns.rightPinnedWidth + 'px'},{'max-width':dataGridControl.dataColumns.rightPinnedWidth + 'px'},{'width':dataGridControl.dataColumns.rightPinnedWidth + 'px'}]">

                    <div class="o365-header-row" data-o365-rowindex="0">
                        <template v-for="(col, colIndex) in columns" :key="colIndex">
                            <template v-if="!col.hide && col.pinned === 'right'">
                                <header-cell :col="col" :colIndex="colIndex" :dataGridControl="dataGridControl" :closeNewRecordsPanel="closeNewRecordsPanel" :bulkUpdateOpen="bulkUpdateOpen" :bulkUpdateSelectedRows="selectedRows"
                                    :showNewRecordButton="showNewRecordButton" :hasNewRecords="hasNewRecords" :createNewRecord="createNewRecord" :group-by="groupBy" :use-left-column-sizers="useLeftColumnSizers"></header-cell>
                            </template>
                        </template>
                    </div>

                </div>

            </div>

             <template v-if="(!dataGridControl.isTable && (dataGridControl.newRecordsPosition === 'above-filters') && !dataGridControl.isLookup && (dataGridControl.dataObject?.hasNewRecords || dataGridControl.newData))">
                <ONewRecordsPanel :ref="cmp => { dataGridControl.newrecordsRef = cmp; }" 
                    :widthScrollContainerRef="widthScrollContainerRef" :dataGridControl="dataGridControl"/>
            </template>

            <div v-if="filterRow" class="o365-filter-row-container vertical-scrollbar-gutter-2" :class="{'o365-new-records-shdaow-top': !dataGridControl.isLookup && (dataGridControl.dataObject?.hasNewRecords || dataGridControl.newData)}" 
            style="display: flex; flex-direction: row;" data-o365-container="F">

                <div class="o365-header-pinned-left border-dark border-bottom" v-if="dataGridControl.dataColumns.hasPinnedLeftColumns" 
                :style="[{'min-width':dataGridControl.dataColumns.leftPinnedWidth + 'px'},{'max-width':dataGridControl.dataColumns.leftPinnedWidth + 'px'},{'width':dataGridControl.dataColumns.leftPinnedWidth + 'px'}]">

                    <div class="o365-header-row bg-light-subtle" data-o365-rowindex="0">
                        <template v-for="(col, colIndex) in columns" :key="col.colId">
                            <template v-if="!col.hide && col.pinned === 'left'">
                                <header-filter-cell :col="col" :colIndex="colIndex" :dataGridControl="dataGridControl" :ref="(copm) => { handleFilterCellMount(copm, col.order) }"></header-filter-cell>
                            </template>
                        </template>
                    </div>

                </div>

                <div class="o365-header-viewport">
                    <div class="o365-grid-container bg-light-subtle border-dark border-bottom" :style="[{'width':dataGridControl.dataColumns.centerWidth + dataGridControl.dataColumns.unusedWidth + 'px'}]">

                        <div class="o365-header-row bg-light-subtle" data-o365-rowindex="0">
                            <template v-for="(col, colIndex) in columns" :key="col.colId">
                                <template v-if="!col.hide && !col.pinned">
                                    <header-filter-cell :col="col" :colIndex="colIndex" :dataGridControl="dataGridControl" :ref="(copm) => { handleFilterCellMount(copm, col.order) }"></header-filter-cell>
                                </template>
                            </template>
                        </div>

                    </div>
                </div>

                <div class="o365-header-pinned-right border-dark border-bottom" v-if="dataGridControl.dataColumns.hasPinnedRightColumns" 
                    :style="[{'min-width':dataGridControl.dataColumns.rightPinnedWidth + 'px'},{'max-width':dataGridControl.dataColumns.rightPinnedWidth + 'px'},{'width':dataGridControl.dataColumns.rightPinnedWidth + 'px'}]">

                    <div class="o365-header-row bg-light-subtle" data-o365-rowindex="0">
                        <template v-for="(col, colIndex) in columns" :key="col.colId">
                            <template v-if="!col.hide && col.pinned === 'right'">
                                <header-filter-cell :col="col" :colIndex="colIndex" :dataGridControl="dataGridControl" :ref="(copm) => { handleFilterCellMount(copm, col.order) }"></header-filter-cell>
                            </template>
                        </template>
                    </div>

                </div>

            </div>

            <!-- <template v-if="false && dataGridControl.newRecordsPosition === 'top' && !dataGridControl.isLookup && (dataGridControl.dataObject?.hasNewRecords || dataGridControl.newData)"> -->
                <!-- <ONewRecordsPanel :ref="cmp => { dataGridControl.newrecordsRef = cmp; }" --> 
                    <!-- :widthScrollContainerRef="widthScrollContainerRef" :dataGridControl="dataGridControl"/> -->
            <!-- </template> -->

        </div>

    </div>
</div>

</template>

<script setup>
import HeaderGroupCell from './components.Header.GroupCell.vue';
import HeaderFilterCell from './components.Header.FilterCell.vue';
import HeaderCell from './components.Header.Cell.vue';

import { ODropdown } from 'o365-ui-components'; 
import ONewRecordsPanel from './components.NewRecordsPanel.vue';
import OBulkUpdate from './components.Header.BulkUpdate.vue';
import DataColumnGroup from './DataGrid.DataColumnGroup.ts';
import DataGridHeader from './DataGrid.Header.ts';
import { ref, computed, toRef, onMounted, nextTick } from 'vue';
import { logger } from 'o365-utils';

const props = defineProps({
    modelValue: null,
    dataGridControl: null,
    containerRef: null,
    filterRow: {
        type: Boolean,
        default: false,
    },
    setViewPortWidth: Function,
    showNewRecordButton: Boolean,
    createNewRecord: Function,
    hasNewRecords: Boolean,
    gridMenu: null,
    hideGridMenu: Boolean,
    groupBy: Boolean,

    useLeftColumnSizers: Boolean
});

const emit = defineEmits(['update:modelValue']);


const exposed = {};
const columns = toRef(props, 'modelValue');
const headerRowRef = ref(null);


//--- Bulk update ---
const hasBulkUpdate = computed(() => {
    return !props.modelValue.every(item => !item.bulkUpdate);
});
//if (hasBulkUpdate.value) {
    const bulkUpdateDropdown = ref(null);
 
    const bulkUpdateCurrentCol = ref(null);
    const bulkUpdateCurrentTarget = ref(null);

    const bulkUpdateIsOpen = ref(false);

    async function bulkUpdateOpen(e, col) {
        bulkUpdateCurrentTarget.value = props.containerRef?.querySelector(`.o365-header-cell:not(.o365-header-group-cell)[o365-field='${col.colId}']`);
        if (!bulkUpdateCurrentTarget.value) { return; }

        bulkUpdateCurrentCol.value = col;
        bulkUpdateIsOpen.value = true;
        await nextTick();
        await bulkUpdateDropdown.value.open();
        try {
            window.requestAnimationFrame(() => {
                bulkUpdateDropdown.value.container.querySelector('input')?.focus();
                bulkUpdateDropdown.value.container.querySelector('input')?.click();
            });
        } catch (ex) {
            logger.error(ex);
        }
    }

    exposed.bulkUpdateCurrentCol = bulkUpdateCurrentCol;
    exposed.bulkUpdateCurrentTarget = bulkUpdateCurrentTarget;
    exposed.bulkUpdateOpen = bulkUpdateOpen;
    exposed.bulkUpdateIsOpen = bulkUpdateIsOpen;
    exposed.bulkUpdateDropdown = bulkUpdateDropdown;
//}

const selectedRows = computed(() => {
    // TODO: Move to SelectionControl on the grid after src data is implemented for it
    if (props.dataGridControl.dataObject == null) {
        if (props.dataGridControl.props.data) {
            return props.dataGridControl.props.data.filter(item => item?.isSelected);
        } else {
            return [];
        }
    }
    // console.log('selectedRows');
    let rows = props.dataGridControl.dataObject.data.filter((item) => item?.isSelected); 
    if (props.dataGridControl
            .dataObject
            .selectionControl.isValidSelection && rows.length === 0) {
        const selectedColumn = props.dataGridControl
            .dataObject
            .selectionControl
            .getColumns(props.dataGridControl.dataColumns.columns)
            .find(x => x.field===bulkUpdateCurrentCol.field);
                    
        if (selectedColumn) {
            rows = props.dataGridControl.dataObject.selectionControl.getRows(props.dataGridControl.dataObject.data);
        }
    }
    return rows;
});

const columnGroups = computed(() => {

    return [...props.dataGridControl.dataColumns.columnGroups].reverse().reduce((groupRows, row, rowIndex) => {

        const previousRow = groupRows[rowIndex - 1] ?? columns.value;

        const groupIdFromCol = (col) => {
            return `${col.parentGroupId}${col.pinned??''}`
        };

        const computedGroupRow = previousRow.reduce((groups, col) => {
            if (col.hide) { return groups; }
            if (col.parentGroupId) {
                const lastGroup = groups[groups.length - 1];
                if (lastGroup?.groupId) {
                    if (lastGroup.groupId === groupIdFromCol(col)) {
                    // if (lastGroup.groupId === col.parentGroupId) {
                        lastGroup.children.push(col);
                        return groups;
                    }
                }
                const initialGroup = row.find(x => x.groupId === col.parentGroupId);
                if (!initialGroup) {
                    groups.push(col);
                    return groups;
                }
                const group = new DataColumnGroup({
                    // groupId: initialGroup.groupId,
                    groupId: groupIdFromCol(col),
                    children: [col],
                    headerName: initialGroup.headerName ?? initialGroup.headername,
                    parentGroupId: initialGroup.parentGroupId,
                    headerTextSlot: initialGroup.headerTextSlot
                    
                });
                groups.push(group);
            } else {
                groups.push(col);
            }
            return groups;
        }, []);

        groupRows.push(computedGroupRow);
        return groupRows;
    }, []).reverse();
});

function getGroup(uid) {
    if (typeof uid !== 'string') { return; }
    const [row, group] = uid.split('-');
    if (!group || !row) { return; }
    return columnGroups.value[row][group];
}

props.dataGridControl.header = new DataGridHeader({
    dataGridControl: props.dataGridControl,
    getGroup: getGroup
});
const headerControl = props.dataGridControl.header;

onMounted(() => {
    headerControl.handleMounted({
        headerContainer: headerRowRef.value
    });
});

async function closeNewRecordsPanel() {
    props.dataGridControl.closeBatchRecords();
}

/*
const [newRecordsContainerRef] = useAutoAnimate({
    disrespectUserMotionPreference: true
});
*/

const widthScrollContainerRef = computed(() => {
    return props.containerRef?.querySelector(".o365-body-horizontal-scroll-viewport");
})

const gridMenuCollapsed = computed(() => {
    return props.gridMenu?.container?.widthSizerElement?.sizerControl?.collapsed  ?? false;
})

function handleFilterCellMount(cellRef, index) {
    headerControl._setFilterCellRef(cellRef, index);
}

defineExpose({...exposed});

</script>
